<template>

    <div>
        <v-card :loading="loading" class="mx-auto" outlined>
            <template slot="progress">
                <v-progress-linear
                        :color="$globalSettings.progressbarClasses"
                        height="10"
                        indeterminate
                ></v-progress-linear>
            </template>

            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="6">
                        <div class="secondary-text">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="navigationBack"><v-icon small>fa-angle-left</v-icon></v-btn> {{ formActions.pageTitle }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right font-size-14 font-italic">
                        {{ $globalHelpers.getUserRoleName(formData.RoleId)  }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="save">
                        <v-row>

                            <v-col cols="12" sm="4">
                                <validation-provider v-slot="{ errors }" name="Name" rules="required">
                                    <v-text-field v-model="formData.Label" type="text" :error-messages="errors" label="Name *" :disabled="loading" required></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <ValidationProvider v-slot="{ errors }" :name="formData.RoleId === $globalEnums.roles.Exporter? 'Exporter Type': 'Department'" rules="required">
                                    <v-select v-model="formData.Type" :error-messages="errors" :items="exporterTypes" item-value="id" item-text="label" :label="formData.RoleId === $globalEnums.roles.Exporter? 'Exporter Type *': 'Department *'" :disabled="loading" required></v-select>
                                </ValidationProvider>
                            </v-col>

                        </v-row>

                        <v-card-actions class="d-flex flex-row-reverse">
                            <v-btn type="submit" class="success" :disabled="invalid || loading">Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card-text>
        </v-card>
    </div>

</template>

<script>
    import Vue from 'vue';
    import { required, email } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });

    export default {
        name: "FactoryType_view",
        data: () => ({
            loading: false,
            
            formActions: {
                pageTitle: '',
            },

            formData: {
                Id: null,
                Label: null,
                Type: null,
                //Editable: false,
            },
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        computed:{
        },
        methods: {
            loadSavedData: async function () {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.factoryType.get, { params: { id: this.$route.params.id} }).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.formData = response.data.Data;
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            save: async function () {
                this.loading = true;
                await this.$axios.post(this.$globalSettings.api.endpoints.factoryType.save, this.formData).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        await this.$router.push({name: 'App.FactoryType.List'});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            navigationBack: async function () {
                this.$router.go(-1);
            },
        },
        async created() {
            this.formActions.pageTitle = 'Add Factory Type';
            if(this.$route.params.id !== '0') {
                this.formActions.pageTitle = 'Edit Factory Type';
                await this.loadSavedData();
            }
            this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id === this.$globalEnums.ExporterTypes.Textile || x.id === this.$globalEnums.ExporterTypes.NonTextile);
        }
    }
</script>

<style scoped>
    .app-name{
        color: #794986;
        font-size: 20px;
    }
    .background{
        background: rgb(101,74,134);
        background: linear-gradient(228deg, rgba(101,74,134,1) 0%, rgba(84,66,145,1) 100%);
    }
    .login-left-bg{

    }
    .login-right-bg{
        background: #512DA8;
    }
    .file-input{
        max-width: 375px;
    }
</style>